
#root {
    min-height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

body {
    min-height: 100%;
    position: relative;
    padding: 0;
    margin: 0;
}
