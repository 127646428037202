.display-pic-container {
    height: 200px;
    width: 200px;
}

.contact-container {
    height: 200px;
    width: 200px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.display-pic {
    border-radius: 50%;
}

.header-row {
    padding-top: 5vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: center;
}

.name-container {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 60vw;
    flex-wrap: wrap;
}

.nav-bar-container {
    justify-content: center;
    font-size: 16px;
    background-color: rgba(240, 248, 255, 0);
}

.ant-menu-horizontal {
    border-bottom: 0;
}

.mobile-menu {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    padding-right: 30px;
}

.mobile-menu-overlay {
    z-index: 100;
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
}

.mobile-menu-button {
    border-radius: 10px;
}

.ant-dropdown-menu-title-content {
    font-size: large;
    padding: 10px 5px;
    border-radius: 10px;
}

.section-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
}
.section-inner-container {
    width: 60vw;
}

.section-card {
    border-radius: 20px;
    text-align: left;
    border-width: 2px;
}

.experience-card {
}

.ant-timeline-item-tail {
    border-color:  #167ddd18;
}

.ant-collapse-header {
    font-weight: bolder;
}

.ant-space-item > a{
    color: inherit;
    font-size: 24px;
}

.project-card {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.562);
}

.project-card .ant-card-actions {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.562);
}

.ant-card-head-title {
    white-space: unset;
}

#footer {
    bottom: 0;
    position: relative;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 2;
}

/* 930 because that's where first bad overflow */
@media only screen and (max-width: 930px) { 
    .contact-container {
        padding-right: 0;
        padding-left: 0;
    }

    .section-inner-container {
        width: 95vw;
    }

    .header-row {
        justify-content: center;
        padding: 5px 25px;
    }

    .header-row .ant-space-item {
        align-self: center;
        text-align: justify;
    }
}